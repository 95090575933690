import { useContext } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { RouteContext } from '../..';
import errorImageUrl from '../../../assets/images/toastie.png';
import settings from "../../../config";

export type ErrorPageProps = {
    title?: string,
    heading: string,
    description: string,
    buttonConfiguration?: {
        text: string,
        path: string,
    },
}

const ErrorPage = ({title, heading, description, buttonConfiguration}: ErrorPageProps) => {
    const {homeRoute} = useContext(RouteContext);
    const {path, text} = buttonConfiguration || {path: homeRoute.path, text: 'Home'};

    return (
        <Row className='flex-column justify-content-center align-items-center'>
            <Col lg={10} className='flex-grow-1'>
                <Row>
                    <Col xl='auto' className='d-flex justify-content-center'>
                        <img src={errorImageUrl} alt='Error' className='mw-100'/>
                    </Col>
                    <div className='w-100 d-xl-none'/>
                    <Col className='d-flex flex-column justify-content-around mx-3 mx-md-0'>
                        <div>
                            {title && <h5 className='text-primary'>{title}</h5>}
                            <h1 className='text-primary display-4 fw-bold'>{heading}</h1>
                            <p>{description}</p>
                            
                            <Row>
                                <Col>
                                    <Button tag='a' color='primary' href={path}>{text}</Button>
                                </Col>
                                <Col>
                                    <Button tag='a' color='primary' href={settings.AUTH0.LOGOUT_URL}>Logout</Button>
                                </Col> 
                            </Row> 
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default ErrorPage;