import { lazy } from "react";
import { RouteConfig } from "../../../types/RouteConfig";
import { nisbetsPeriodManagementRoute } from "../period-management/route";

export const nisbetsPeriodsManagementRoute: RouteConfig<'with-page'> = {
    name: 'Periods',
    path: '/nisbets/periods',
    page: lazy(() => import('.')),
    roles: ['ncassAdmin'],
    dynamicChildren: [
        nisbetsPeriodManagementRoute,
    ],
}