export type Brand<T, K extends string> = T & { __brand: K }

export type DateTimeString = Brand<string, 'Date Time String'>;
export type GuidString = Brand<string, 'GUID String'>;
export type UriString = Brand<string, 'URI String'>;
export type MimeTypeString = Brand<string, 'Mime Type String'>;
export type Base64String = Brand<string, 'Base64 String'>;

export type CurrencyNumber = Brand<number, 'Currency Number'>;

export function asGuid(guid: string) : GuidString {
    if (isUUID(guid))
        return guid as GuidString;
    else
        return '' as GuidString;
}

function isUUID(s : string) : boolean{
    let regex = new RegExp("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$");
    return regex.test(s);
}