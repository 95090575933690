import { RouteConfig } from "../../types/RouteConfig";
import { nisbetsPeriodsManagementRoute } from "./periods-management/route";
import { nisbetsSignupManagementRoute } from "./signup-management/route";

export const nisbetsRoutes: RouteConfig<'with-children'> = {
    name: 'Nisbets',
    icon: 'mdi mdi-stove',
    children: [
        nisbetsPeriodsManagementRoute,
        nisbetsSignupManagementRoute,
    ]
}