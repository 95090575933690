import { RouteConfig } from "../../../types/RouteConfig";
import { bookerCashbackViewRoute } from "./cashback-view/route";
import { bookerSignupRoute } from "./signup/route";

export const bookerRoutes: RouteConfig<'with-children'> = {
    name: 'Booker',
    children: [
        bookerSignupRoute,
        bookerCashbackViewRoute,
    ]
}