import { lazy } from "react";
import { RouteConfig } from "../../../types/RouteConfig";
import { errorMembershipRoute } from "../../error/PageMembershipStatusError/route";

export const companyDocumentsRoute: RouteConfig<'with-page'> = {
    name: 'Company Documents',
    page: lazy(() => import('.')),
    path: '/documents/company',
    flags: ['member-route'],
    permissions: ['companyDocuments'],
    permissionsRedirect: errorMembershipRoute,
}