import ReactDOM from 'react-dom/client';
import App from './App';
import {ClearPersistedStorage} from "./components/MembershipImpersonation/MembershipImpersonationContext";


export const clearWorkerService = () => {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {
            registration.unregister();
        }
    });
}

const root = ReactDOM.createRoot( document.getElementById('root'));
ClearPersistedStorage();
clearWorkerService();
root.render(<App />);
