import React from 'react';
import settings from '../../../../config';
import ErrorPage from '../ErrorPage';

const ErrorBadPermissions = () => (
    <ErrorPage
        heading="Oops! You aren't authorised."
        description="It looks like you don't have the permissions to view this page. Please contact your account manager."
        buttonConfiguration={{path: settings.CONTACT_US_URL, text: 'Contact us'}}/>
)

export default ErrorBadPermissions;