import { RouteConfig } from "../../../types/RouteConfig";
import { nisbetsCashbackViewRoute } from "./cashback-view/route";
import { nisbetsSignupRoute } from "./signup/route";

export const nisbetsRoutes: RouteConfig<'with-children'> = {
    name: 'Nisbets Cashback',
    children: [
        nisbetsSignupRoute,
        nisbetsCashbackViewRoute,
    ]
}