import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";
import { NcassUser } from "../models/auth0/NcassUser";

export const useNcassAuth0 = (): Auth0ContextInterface<NcassUser> => {
    const auth0 = useAuth0();
    return useMemo(() => {
        const {
            user,
            ...auth0Rest
        } = auth0;

        if (user === undefined) {
            return {
                ...auth0Rest,
                user: undefined,
            }
        }

        const {
            'http://ncass.org.uk/roles': roles,
            'http://ncass.org.uk/membershipNumber': membershipNumber,
            'http://ncass.org.uk/dynamicsAccountId' : accountId,
            'http://ncass.org.uk/ncassAccountStatus': ncassAccountStatus,
            email,
            ...rest
        } = user;

        return {
            ...auth0Rest,
            user: {...rest, roles, membershipNumber, accountId, ncassAccountStatus, email},
        }
    }, [auth0]);
}