import { createMemberPermissionsWrapper } from "../components/member-permissions/MemberPermissionsWrapper";
import { createMembershipImpersonationRedirect } from "../components/MembershipImpersonation/MemberImpersonationRedirect";
import { Flag, RouteConfig } from "./types/RouteConfig";

export type FlattenedRoute = [RouteConfig<'with-page'>, RouteConfig<'with-page'>];

export const flattenRoutes = (routes: RouteConfig[]): FlattenedRoute[] => {
    // This could be achieved with a call to .flatMap, but Safari 11 does not support that functionality so we implement it manually
    return routes
        .map(route => {
            if ('children' in route) {
                return flattenRoutes(route.children);
            }

            if ('url' in route) {
                return null;
            }

            const flattenedDynamicChildren = flattenRoutes(route.dynamicChildren || []);
            return [...transformRoute(route), ...flattenedDynamicChildren];
        })
        .reduce(flatten, [])
        .filter(route => route !== null);
}

export const mapParentRoutes = (routes: RouteConfig[], parents: RouteConfig[] = []): Map<RouteConfig, RouteConfig[]> => {
    const map = new Map(
        routes.map(route => [route, parents])
    );

    const mapChildRoutes = (route: RouteConfig, childRoutes: RouteConfig[]) => {
        const childMap = mapParentRoutes(childRoutes, [...parents, route]);
        childMap.forEach((value, key) => {
            map.set(key, value);
        })
    }

    routes.forEach(route => {
        if ('children' in route) {
            mapChildRoutes(route, route.children);
            return;
        }

        if ('url' in route) {
            return;
        }

        const dynamicChildren = route.dynamicChildren || [];
        if (dynamicChildren.length !== 0) {
            mapChildRoutes(route, dynamicChildren);
        }
    })

    return map;
}

const transformRoute = (route: RouteConfig<'with-page'>): FlattenedRoute[] => {
    // This could be achieved with calls to .flatMap, but Safari 11 does not support that functionality so we implement it manually
    return [route]
        .map(transformMemberRoute(route))
        .map(transformSingleElementToArray)
        .reduce(flatten, [])
        .map(transformRouteWithPermissions(route))
        .map(transformWorkInProgressRoute(route))
        .map(transformSingleElementToArray)
        .reduce(flatten, [])
        .map(mappedRoute => [mappedRoute, route]);
}

type TransformRouteFunc = (refRoute: RouteConfig<'with-page'>) => (route: RouteConfig<'with-page'>) => RouteConfig<'with-page'> | RouteConfig<'with-page'>[];

const hasFlag = (route: RouteConfig<'with-page'>, flag: Flag) => route.flags && route.flags.includes(flag);

const transformRouteWithPermissions: TransformRouteFunc = () => route => {
    const requiredPermissions = route.permissions;

    if (requiredPermissions === undefined || requiredPermissions.length === 0) {
        return route;
    }

    return {
        ...route,
        page: createMemberPermissionsWrapper(requiredPermissions, route.page, route.permissionsRedirect),
    }
}

const transformMemberRoute: TransformRouteFunc = refRoute => route => {
    if (!hasFlag(refRoute, 'member-route')) {
        return route
    }

    const withMembershipNumberRoute: RouteConfig<'with-page'> = {
        ...route,
        page: route.page,
        roles: route.roles || ['ncassMember', 'accountManager'],
    }

    return [
        withMembershipNumberRoute,
        {
            ...withMembershipNumberRoute,
            page: createMembershipImpersonationRedirect(route.path),
            path: `/:membershipNumber${route.path}`,
            roles: ['accountManager']
        },
    ]
}

const transformWorkInProgressRoute: TransformRouteFunc = refRoute => route => {
    if (!hasFlag(route, 'work-in-progress')) {
        return route
    }

    return {...route, roles: ['developer']}
}

const flatten = <T extends unknown>(flattened: T[], toFlatten: T[]): T[] => flattened.concat(toFlatten);

const transformSingleElementToArray = <T extends unknown>(element: T | T[]) => Array.isArray(element) ? element : [element];