import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import wretch from "wretch";
import { Wretch } from "wretch/types";
import settings from "../config";

type UseApiReturnValue = {
    getApi: () => Promise<Wretch>,
    getApiWithoutAPIGateway: () => Promise<Wretch>,
}

export default function useApi(): UseApiReturnValue {
    const { getAccessTokenSilently } = useAuth0();

    const getApi = useCallback(async () => {
        const token: string = await getAccessTokenSilently()
        return wretch()
            .url(settings.API_URL)
            .auth(`Bearer ${token}`)
    }, [getAccessTokenSilently])

    const getApiWithoutAPIGateway = useCallback(async () => {
        return wretch()
    }, [])

    return {
        getApi,
        getApiWithoutAPIGateway,
    }
}