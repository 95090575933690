import { lazy } from "react";
import { RouteConfig } from "../../types/RouteConfig";
import { createNewUnitPremisesRoute } from "./create/route";
import { unitPremiseDetailsRoute } from "./details/route";
import { unitPremiseDocumentsRoute } from "./documents/route";

export const unitPremisesRoute: RouteConfig<'with-page'> = {
    name: 'My Units / Premises',
    page: lazy(() => import('.')),
    path: '/unit-premises',
    flags: ['member-route'],
    permissions: ['tradingUnits'],
    icon: 'dripicons-store',
    dynamicChildren: [
        createNewUnitPremisesRoute,
        unitPremiseDetailsRoute,
        unitPremiseDocumentsRoute
    ]
}