import { lazy } from "react";
import { RouteConfig } from "../../../../types/RouteConfig";
import { errorMembershipRoute } from "../../../error/PageMembershipStatusError/route";

export const healthAndSafetyPolicyRoute: RouteConfig<'with-page'> = {
    name: 'Health & Safety Policy',
    page: lazy(() => import('.')),
    path: '/documents/company/health-and-safety-policy',
    flags: ['member-route'],
    permissions: ['companyDocuments'],
    permissionsRedirect: errorMembershipRoute,
}