import { RouteConfig } from "../../types/RouteConfig";
import { myDetailsRoute } from "./my-details/route";
import { myPaymentsAndInvoicesRoute } from "./payment-and-invoices/route";
import { myUpgradeRoute } from "./upgrade/route";
import { myRenewRoute } from "./renew/route";
import {planToRenew} from "./plan-to-renew/route";
import {noPlanToRenew} from "./no-plan-to-renew/route";

export const myAccountRoutes: RouteConfig<'with-children'> = {
    name: 'My Account',
    icon: 'mdi mdi-settings-outline',
    children: [
        myDetailsRoute,
        myPaymentsAndInvoicesRoute,
        myUpgradeRoute,
        myRenewRoute,
        planToRenew,
        noPlanToRenew
    ],
}
