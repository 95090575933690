import { RouteConfig } from "../../../types/RouteConfig";
import { biopakCashbackViewRoute } from "./cashback-view/route";
import { biopakSignupFormRoute } from "./signup/route";

export const biopakRoutes: RouteConfig<'with-children'> = {
    name: 'BioPak Discount & Cashback',
    children: [
        biopakSignupFormRoute,
        biopakCashbackViewRoute,
    ]
}