import React, {useState} from "react";
import {Navigate, useParams} from "react-router";
import LoadingPlaceholder from "../LoadingPlaceholder";
import {useMembershipImpersonationContext} from "./MembershipImpersonationContext";
import {GuidString} from "../../models/BrandedTypes";
import useApi from "../../hooks/useApi";
import useEffectAsync from "../../hooks/useEffectAsync";

export type MemberImpersonationRedirectProps = {
    path: string
}

type MembershipParams = {
    membershipNumber?: string
}

const MembershipImpersonationRedirect = ({path}: MemberImpersonationRedirectProps): JSX.Element => {

    const { getApi } = useApi();
    const {membershipNumber} = useParams<MembershipParams>();
    const {setImpersonationOverride} = useMembershipImpersonationContext();
    const [canContinue, setCanContinue] = useState(false);

     useEffectAsync( async () => {
        const getAccountId = async (): Promise<GuidString> => {
            const api = await getApi();
            const response = api.get(`/members/${membershipNumber}/details/account-id`);
            return await response.json();
        }

        let accountId = await getAccountId();

        let membershipNumberOverride = membershipNumber
        let accountIdOverride = accountId;

        setImpersonationOverride({membershipNumberOverride, accountIdOverride});
        setCanContinue(true);
    }, [membershipNumber, setImpersonationOverride, setCanContinue]);

    return canContinue
        ? <Navigate to={path}/>
        : <LoadingPlaceholder/>
}

export const createMembershipImpersonationRedirect = (path: string): React.ComponentType => {
    return () => (
        <MembershipImpersonationRedirect path={path}/>
    );
}