import { lazy } from "react";
import { RouteConfig } from "../../../types/RouteConfig";
import { bookerPeriodManagementRoute } from "../period-management/route";

export const bookerPeriodsManagementRoute: RouteConfig<'with-page'> = {
    name: 'Periods',
    path: '/booker/periods',
    page: lazy(() => import('.')),
    flags: ['work-in-progress'],
    dynamicChildren: [
        bookerPeriodManagementRoute,
    ]
}