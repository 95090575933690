import { Spinner } from 'reactstrap';

const LoadingPlaceholder = (): JSX.Element => {
    return(
        <div className="d-flex justify-content-center align-items-center p-4 text-primary h-auto">
            <Spinner type="grow" />
        </div>
    )
}

export default LoadingPlaceholder;