import { createBrowserHistory } from 'history';
import React, { useContext, useEffect } from 'react';
import { Navigate, Routes, useLocation } from 'react-router';
import { Route, unstable_HistoryRouter as ReactRouter } from 'react-router-dom';
import { RouteContext, RouteProvider } from '.';
import withLayout from '../components/common/Layout';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import useGoogleAnalytics from '../hooks/useGoogleAnalytics';
import { useNcassAuth0 } from '../hooks/useNcassAuth0';
import { useIsAuthorized } from './hooks/useIsAuthorized';
import { notFoundRoute } from './pages/error/PageNotFound/route';
import { unauthorizedRoute } from './pages/error/PageUnauthorized/route';
import { RouteConfig } from './types/RouteConfig';
import {localStorageKey} from '../components/MembershipImpersonation/MembershipImpersonationContext';
import {maintenanceRoute} from "./pages/maintenance/route";
import { logoutRoute } from './pages/account/logout/route';
import settings from "../config";
import {errorMembershipRoute} from "./pages/error/PageMembershipStatusError/route";
import {myRenewRoute} from "./pages/my-account/renew/route";
import {dashboardPageRoute} from "./pages/member-home/route";
import {myPaymentsAndInvoicesRoute} from "./pages/my-account/payment-and-invoices/route";
type ActiveRouteProps = {
    route: RouteConfig<'with-page'>,
    refRoute: RouteConfig<'with-page'>,
}

export const history = createBrowserHistory();

const Router = () => {
    return (
        <ReactRouter history={history}>
            <RouteProvider>
                <AppRoutes/>
            </RouteProvider>
        </ReactRouter>
    )
}

const AppRoutes = () => {
    useGoogleAnalytics();

    const {flattenedRoutes} = useContext(RouteContext);

    return (
        <Routes>
            {flattenedRoutes.map((flattenedRoute) => {
                const [route, refRoute] = Array.isArray(flattenedRoute)
                    ? flattenedRoute
                    : [flattenedRoute, flattenedRoute]

                return (
                    <Route key={route.path} path={route.path} element={<ActiveRoute route={route} refRoute={refRoute}/>}/>
                )
            })}

            <Route element={<ActiveRoute route={notFoundRoute} refRoute={notFoundRoute}/>}/>
        </Routes>
    );
}

const ActiveRoute = withLayout(({route, refRoute}: ActiveRouteProps) => {
    const {setActiveRoute} = useContext(RouteContext);

    useEffect(() => {
        setActiveRoute(refRoute);
    }, [refRoute, setActiveRoute]);

    const isAuthorized = useIsAuthorized();
    const { user, isLoading, isAuthenticated, loginWithRedirect } = useNcassAuth0();
    const currentLocation = useLocation();

    useEffect(() => {
        const auth0Login = async () => {
            if (!isLoading && !isAuthenticated) {
                localStorage.removeItem(localStorageKey);
                await loginWithRedirect({
                    appState: { targetUrl: `${currentLocation.pathname}${currentLocation.search}` }
                });
            }
        }
        auth0Login();
    }, [isLoading, isAuthenticated, loginWithRedirect, currentLocation]);

    //i want to logout therefor override everything and let me log out
    if(route.path === logoutRoute.path) {
        return <route.page/>;
    }
    
    if (!isAuthenticated || !user) {
        return <LoadingPlaceholder/>;
    }

    if(settings.MAINTENANCE_MODE == 'true'
        && !user.roles.find(role => role == "developer")
        && route.path != maintenanceRoute.path){
        return <Navigate to={maintenanceRoute.path}/>
    }

    if( user.ncassAccountStatus // Nisbets, Biopak (etc) employees will not have an account status so should be excluded from this logic
        && user.ncassAccountStatus !== 'Active'
        && user.ncassAccountStatus !== 'Renew Soon'
        && route.path != errorMembershipRoute.path
        && route.path != myRenewRoute.path
        && route.path != myPaymentsAndInvoicesRoute.path
        && route.path != logoutRoute.path
        && route.path != dashboardPageRoute.path
        ){
        return <Navigate to={errorMembershipRoute.path}/>
    }

    if (isAuthorized(route)) {
        return <route.page/>;
    }

    return <Navigate to={unauthorizedRoute.path}/>
});

export default Router;