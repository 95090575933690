import { useMemo } from "react"
import { useMembershipImpersonationContext } from "../components/MembershipImpersonation/MembershipImpersonationContext"
import { useNcassAuth0 } from "./useNcassAuth0"
import {asGuid, GuidString} from "../models/BrandedTypes";

export type MembershipDetails = {
    membershipNumber: string,
    accountId: GuidString
}

export const unknownMembershipNumber = 'unknown'
export const unknownAccountId = asGuid('00000000-0000-0000-0000-000000000000')
export const useMembership = () => {
    const { isAuthenticated, user } = useNcassAuth0();
    const { isImpersonatingMember, membershipNumberOverride, accountIdOverride } = useMembershipImpersonationContext();

    const membershipContextValue = useMemo<MembershipDetails>(() => {
        if (isImpersonatingMember) {
            return {
                membershipNumber: membershipNumberOverride,
                accountId: accountIdOverride,
            }
        }

        if (isAuthenticated && user.membershipNumber) {
            return {
                membershipNumber: user.membershipNumber,
                accountId: user.accountId,
            }
        }

        return {
            membershipNumber: unknownMembershipNumber,
            accountId: unknownAccountId
        }
    }, [membershipNumberOverride, accountIdOverride, isAuthenticated, user, isImpersonatingMember]);

    return membershipContextValue;
}