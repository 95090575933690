import { lazy } from "react";
import { RouteConfig } from "../../../../../types/RouteConfig";
import { errorMembershipRoute } from "../../../../error/PageMembershipStatusError/route";

export const foodSafetyRiskAssessmentRoute: RouteConfig<'with-page'> = {
    name: 'Food Safety Risk Assessment',
    page: lazy(() => import('.')),
    path: '/documents/company/food-safety-risk-assessment',
    flags: ['member-route', 'no-menu-item'],
    permissions: ['companyDocuments'],
    permissionsRedirect: errorMembershipRoute,
}