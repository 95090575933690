import { RouteConfig } from "../../../types/RouteConfig";
import { calorCashbackViewRoute } from "./cashback-view/route";
import { calorClaimFormRoute } from "./claim-form/route";
import { calorClaimsViewRoute } from "./claims-view/route";
import { calorSignUpFormRoute } from './sign-up/route';

export const calorRoutes: RouteConfig<'with-children'> = {
    name: 'Cashback On Calor',
    children: [
        calorSignUpFormRoute,
        calorCashbackViewRoute,
        calorClaimFormRoute,
        calorClaimsViewRoute,
    ]
}