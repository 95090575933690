import { lazy } from "react";
import { RouteConfig } from "../../../../types/RouteConfig";
import { errorMembershipRoute } from "../../../error/PageMembershipStatusError/route";

export const coshhFormRoute: RouteConfig<'with-page'> = {
    name: 'COSHH Assessment',
    path: '/documents/company/coshh',
    page: lazy(() => import('.')),
    permissions: ['companyDocuments'],
    permissionsRedirect: errorMembershipRoute,
    flags: ['member-route'],
}