import settings from '../../config';
import { RouteConfig } from "../types/RouteConfig";

export const digitalSmsRoute: RouteConfig<'external'> = {
    name: 'Digital SMS',
    url: `${settings.DIGITAL_SMS_URL}`,
    icon: 'dripicons-checklist',
    roles: ['ncassMember', 'accountManager'],
    target: '_self',
}

export const legacyControlPanelRoute: RouteConfig<'external'> = {
    name: 'Control Panel',
    url: `${settings.CONTROL_PANEL_URL}/memberarea/member_home_v1.aspx?forcestay=true`,
    icon: 'mdi mdi-view-dashboard-outline',
    roles: ['ncassMember', 'accountManager'],
    target: '_self',
}

export const legacySendDocumentsRoute: RouteConfig<'external'> = {
    name: 'Send Documents',
    url: `${settings.CONTROL_PANEL_URL}/memberarea/member_document_forward.aspx`,
    icon: 'mdi mdi-file-send',
    roles: ['ncassMember', 'accountManager'],  
    target: '_self',  
}

export const legacyTrainingRoute: RouteConfig<'external'> = {
    name: 'Training',
    url: `${settings.CONTROL_PANEL_URL}/memberarea/member_training_v1.aspx`,
    icon: 'mdi mdi-dumbbell',
    roles: ['ncassMember', 'accountManager'],
    target: '_self',
}

export const legacyWorkOppRoute: RouteConfig<'external'> = {
    name: 'Work Opportunities',
    url: `${settings.CONTROL_PANEL_URL}/memberarea/member_workopp.aspx`,
    icon: 'mdi mdi-tie',
    roles: ['ncassMember', 'accountManager'],
    target: '_self',
}

export const legacyMyDetailsRoute: RouteConfig<'external'> = {
    name: 'My Details',
    url: '/maintenance',
    icon: 'dripicons-user',
    roles: ['ncassMember', 'accountManager'],
    target: '_self',
}

export const legacyFoodHygieneRoute: RouteConfig<'external'> = {
    name: 'My Food Hygiene',
    url: `${settings.CONTROL_PANEL_URL}/memberarea/member_fhrs.aspx`,
    icon: 'dripicons-cutlery',
    roles: ['ncassMember', 'accountManager'],
    target: '_self',
}

export const wordpressContactUsRoute: RouteConfig<'external'> = {
    name: 'Contact Us',
    url: 'https://www.ncass.org.uk/contact-us/',
    icon: 'dripicons-phone',
    roles: ['ncassMember'],
    flags:['member-renew'],
    target: '_self',
}

export const wordpressMemberResourcesRoute: RouteConfig<'external'> = {
    name: 'Member Resources',
    url: 'https://www.ncass.org.uk/members-only/member-resources/',
    icon: 'mdi mdi-file-document-box-multiple-outline',
    roles: ['ncassMember'],
    target: '_self',
}

export const wordpressFindAGasEngineerRoute: RouteConfig<'external'> = {
    name: 'Find A Gas Engineer',
    url: 'https://www.ncass.org.uk/members-only/gas-engineers/',
    icon: 'mdi mdi-account-search-outline',
    roles: ['ncassMember'],
    target: '_self',
}

export const legacyLocalAuthorityRoute: RouteConfig<'external'> = {
    name: 'My Local Authority',
    url: `${settings.CONTROL_PANEL_URL}/memberarea/member_my_local_authority.aspx`,
    icon: 'mdi mdi-bank',
    roles: ['ncassMember'],
    target: '_self',
}


export const legacyMyWorkOppsRoute: RouteConfig<'external'> = {
    name: 'My WorkOpps',
    url: `${settings.CONTROL_PANEL_URL}/place_workopportunity.aspx`,
    icon: 'mdi mdi-cellphone',
    roles: ['ncassMember', 'accountManager'],
    target: '_self',
}

export const legacyForSaleItemsRoute: RouteConfig<'external'> = {
    name: 'For Sale Items',
    url: `${settings.CONTROL_PANEL_URL}/ncass_catering_equipment_for_sale_add_new.aspx`,
    icon: 'mdi mdi-tag-outline',
    roles: ['ncassMember', 'accountManager'],
    target: '_self',
}