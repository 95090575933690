import React from 'react';
import ErrorPage from '../ErrorPage';
import settings from '../../../../config';

const ErrorPageContactUs = () => {
    return <ErrorPage
        title='Unknown Error'
        heading='Oops! There was an unexpected error.'
        description='It looks like an unknown error has occurred; contact us to get to the bottom of it.'
        buttonConfiguration= {{path: settings.CONTACT_US_URL, text: 'Contact us'}} />
};
export default ErrorPageContactUs;
