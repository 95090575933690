import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import settings from '../config';
import { history } from '../routes/Router';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${settings.APPLICATION_INSIGHTS_INSTRUMENTATIONKEY};IngestionEndpoint=${settings.APPLICATION_INSIGHTS_INGESTIONENDPOINT};LiveEndpoint=${settings.APPLICATION_INSIGHTS_LIVEENDPOINT}`,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history }
    }
  },
});
appInsights.loadAppInsights();

export { reactPlugin };