import { useCallback } from "react";
import { useNcassAuth0 } from "../../hooks/useNcassAuth0";
import { Role } from "../../models/auth0/Role";
import { Flag, RouteConfig } from "../types/RouteConfig";

export const useIsAuthorized = (): ((route: RouteConfig) => boolean) => {
    const { user } = useNcassAuth0();

    return useCallback(route => {
        if (user === undefined) {
            return false;
        }

        const isAuthorizedData = {
            userRoles: user.roles || [],
            routeRoles: route.roles || [],
            routeFlags: ('flags' in route && route.flags) || [],
        }

        const matchingPredicate = predicates.find(predicate => predicate.matches(isAuthorizedData))

        return matchingPredicate.returnValue instanceof Function
            ? matchingPredicate.returnValue(isAuthorizedData)
            : matchingPredicate.returnValue;
    }, [user]);
}

type AuthorizationData = {
    userRoles: Role[],
    routeRoles: Role[],
    routeFlags: Flag[],
}

type IsAuthorizedPredicate = {
    matches: (_: AuthorizationData) => boolean,
    returnValue: boolean | ((_: AuthorizationData) => boolean),
}

const predicates: IsAuthorizedPredicate[] = [
    {
        matches: ({routeFlags}) => routeFlags.includes('work-in-progress'),
        returnValue: ({userRoles}) => userRoles.includes('developer'),
    },
    {
        matches: ({routeRoles}) => routeRoles.length === 0,
        returnValue: true,
    }, 
    {
        matches: ({routeFlags, userRoles}) => routeFlags.includes('member-renew') && userRoles.length === 0,
        returnValue: true,
    },
    {
        matches: ({userRoles}) => userRoles.length === 0,
        returnValue: false,
    },
    {
        matches: ({userRoles}) => userRoles.includes('developer'),
        returnValue: true,
    },
    {
        matches: () => true,
        returnValue: ({userRoles, routeRoles}) => userRoles.some(role => routeRoles.includes(role)),
    },
]