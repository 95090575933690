import { useCallback, useEffect, useState } from 'react';
import QueryStringAddon from 'wretch/addons/queryString';
import { Wretch, WretchError, WretchResponseChain } from 'wretch/types';
import useApi from '../../hooks/useApi';
import { useResetableState } from '../../hooks/useResetableState';
import { defaultResponseTransformation, renderApiResourceChildren, renderErrorDefault, renderLoadingDefault } from './helpers';
import { ApiResourceProps } from './types';

const ApiResource = <TBody, TResponse, TData = TResponse>(props: ApiResourceProps<TBody, TResponse, TData>): JSX.Element => {
    const {
        resource,
        renderError = (error, defaultRender) => defaultRender(error),
        renderLoading = renderLoadingDefault,
        disableRerenderLoading = false,
        method = 'get',
        responseType = 'json',
        body,
        queryParameters,
        children,
        managedDataState: dataState,
        transform = defaultResponseTransformation,
        onDataReceived,
        noLoadingSpinner
    } = props;

    const [loading, setLoading] = useState(true);
    const [error, setError, resetError] = useResetableState<WretchError>(null);

    const [reloadData, setReloadData] = useState<{}>({});

    const internalDataState = useState<TData>(null);
    const [data, setData] = dataState === undefined ? internalDataState : dataState;

    const { getApi } = useApi();

    const setApiMethod = (api: Wretch) : WretchResponseChain<unknown> =>
    {
        switch (method) {
            case 'get':
               return api.get()
            case 'post':
                return api.post(body)
        }
    }

    const makeApiCall = useCallback((api: Wretch, isCancelled: boolean): Promise<any> => {
        if (queryParameters !== undefined) {
            api = api
                .addon(QueryStringAddon)
                .query(queryParameters);
        }
        switch (responseType){
            case 'json':
                return setApiMethod(api)
                    .json((data: TResponse) => {
                        if (!isCancelled) {
                            const transformedData = transform(data);
                            setData(transformedData);
                            if (onDataReceived) {
                                onDataReceived(transformedData);
                            }
                        }

                        return data;
                    })

            case 'text':
                return setApiMethod(api)
                    .text((data: string) => {
                        if(!isCancelled) {
                            setData(data as any);
                        }
                    })
        }
    }, [method, JSON.stringify(body), JSON.stringify(queryParameters)])

    const reloadDataCallback = useCallback(() => setReloadData({}), []);

    useEffect(() => {
        let isCancelled = false;

        getApi().then(api => {
            if (!isCancelled) {
                if (!disableRerenderLoading) {
                    setLoading(true);
                    resetError();
                }
                makeApiCall(api.url(resource), isCancelled)
                    .catch(error => !isCancelled && setError(error))
                    .then(() => !isCancelled && setLoading(false));
            }
        });

        return () => {
            isCancelled = true
        };
    }, [resource, reloadData, disableRerenderLoading, makeApiCall, transform, getApi, resetError, setData, setError]);

    useEffect(resetError, [data, resetError]);

    if (error) {
        return renderError(error, renderErrorDefault, reloadDataCallback);
    }

    if (loading) {
        return noLoadingSpinner ? null : renderLoading();
    }

    return <>
        {renderApiResourceChildren(children, data, reloadDataCallback)}
    </>
}

export default ApiResource;