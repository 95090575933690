import ReactGA from 'react-ga4';
import settings from '../config';

const TRACKING_ID = "G-LLQBR6G2YR"

function init() {
  // Enable debug mode on the local development environment
  const isDev = settings.ENVIRONMENT === "development";
  ReactGA.initialize([
    {
      trackingId: TRACKING_ID,
      gaOptions: {
        debug_mode: isDev,
      },
      gtagOptions: {
        debug_mode: isDev,
      },
    },
  ]);
}

function sendEvent(payload) {
  ReactGA.event(payload)
}

const moduleDefaults = {
  init,
  sendEvent,
}

export default moduleDefaults;
