import React, {useContext} from 'react';
import {unknownMembershipNumber, useMembership} from '../../hooks/useMembership';
import ApiResource from '../ApiResource';
import {MemberPermissions} from './types';
import {ErrorRenderer} from '../ApiResource/types';
import ErrorBadPermissions from '../../routes/pages/error/PageBadPermissionsError';

const noAccessPermissions: MemberPermissions = {
    workOps: 'NoAccess',
    sendCompanyDocuments: 'NoAccess',
    riskAssessments: 'NoAccess',
    companyDocuments: 'NoAccess',
    tradingUnits: 'NoAccess',
    training: 'NoAccess',
    rewards: 'NoAccess',
    wallet: 'NoAccess'
}

const MemberPermissionsContext = React.createContext<MemberPermissions>(noAccessPermissions);

export const MemberPermissionsProvider = ({children}: React.PropsWithChildren<{}>) => {
    const {membershipNumber} = useMembership();

    const PermissionsResource = membershipNumber === unknownMembershipNumber
        ? UnknownMembershipPermissions
        : ApiResourcePermissions

    return (
        <PermissionsResource membershipNumber={membershipNumber}>
            {(permissions: MemberPermissions) => (
                <MemberPermissionsContext.Provider value={permissions}>
                    {children}
                </MemberPermissionsContext.Provider>
            )}
        </PermissionsResource>
    )
}

type PermissionsResourceProps = {
    membershipNumber: string,
    children: (permissions: MemberPermissions) => React.ReactNode,
}

const renderError: ErrorRenderer = (error, defaultRenderer) => {
    return <ErrorBadPermissions/>
}

const ApiResourcePermissions = ({membershipNumber, children}: PermissionsResourceProps) => (
    <ApiResource resource={`/members/${membershipNumber}/permissions`} renderError={renderError}>
        {(permissions: MemberPermissions) => children(permissions)}
    </ApiResource>
) 

const UnknownMembershipPermissions = ({children}: PermissionsResourceProps) => <>
    {children(noAccessPermissions)}
</>

export const useMemberPermissions = () => useContext(MemberPermissionsContext);