// @ts-nocheck
import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import Loadable from 'react-loadable';
import settings from "../../config"

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <LoadingPlaceholder />;

// All layouts/containers
const VerticalLayout = Loadable({
    loader: () => import('../../layouts/Vertical'),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading,
});


/**
 * Exports the component with layout wrapped to it
 * @param {} WrappedComponent
 */
const withLayout = (WrappedComponent: any) => {
    type HOCProps = {
        layout: { layoutType?: string },
    };
    const HOC = class extends Component<HOCProps> {
        /**
         * Returns the layout component based on different properties
         */
        getLayout = () => {

            let layoutCls = VerticalLayout;

            switch (this.props.layout.layoutType) {
                default:
                    layoutCls = VerticalLayout;
                    break;
            }
            return layoutCls;
        };

        render() {
            const showLayout = settings.SHOW_LAYOUT;
            const Layout = this.getLayout();
            
            const render = !showLayout ?
                <Suspense fallback={loading()}>
                    <WrappedComponent {...this.props} />
                </Suspense>
                :
                <Suspense fallback={loading()}>
                    <Layout {...this.props}>
                        <WrappedComponent {...this.props} />
                    </Layout>
                </Suspense>;
            return render;
        }
    };

    const mapStateToProps = state => {
        return {
            layout: state.Layout,
        };
    };

    return connect(
        mapStateToProps,
        null
    )(HOC);
};

export default withLayout;
