import { RouteConfig } from "../../types/RouteConfig";
import { badPermissionsRoute } from "./PageBadPermissionsError/route";
import { contactusRoute } from "./PageContactUsError/route";
import { errorMembershipRoute } from "./PageMembershipStatusError/route";
import { notFoundRoute } from "./PageNotFound/route";
import { serverErrorRoute } from "./PageServerError/route";
import { unauthorizedRoute } from "./PageUnauthorized/route";
import { unknownErrorRoute } from "./PageUnknownError/route";
import { loginIssueRoute } from "./loginIssueError/route"

export const errorRoutes: RouteConfig<'with-children'> = {
    name: 'Error Pages',
    roles: ['developer'],
    icon: 'dripicons-warning',
    children: [
        unauthorizedRoute,
        notFoundRoute,
        serverErrorRoute,
        unknownErrorRoute,
        contactusRoute,
        badPermissionsRoute,
        errorMembershipRoute,
        loginIssueRoute,
    ]
}