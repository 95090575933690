import React from 'react';
import { Navigate } from "react-router-dom";
import { WretchError } from "wretch/types";
import { StandardApiResponse } from '../../models/StandardApiResponse';
import { notFoundRoute } from '../../routes/pages/error/PageNotFound/route';
import { serverErrorRoute } from '../../routes/pages/error/PageServerError/route';
import { unauthorizedRoute } from '../../routes/pages/error/PageUnauthorized/route';
import { unknownErrorRoute } from '../../routes/pages/error/PageUnknownError/route';
import LoadingPlaceholder from "../LoadingPlaceholder";
import { ApiResourceChildren } from "./types";


export const renderApiResourceChildren = <T extends unknown>(children: ApiResourceChildren<T>, data: T, reloadData: () => void) => {
    return children instanceof Function
        ? children(data, reloadData)
        : React.cloneElement(children, {data, reloadData});
}

export const renderErrorDefault = (error: WretchError): JSX.Element => {
    switch (error.status) {
        case 401:
            return <Navigate to={unauthorizedRoute.path}/>
        case 404:
            return <Navigate to={notFoundRoute.path}/>
        case 500:
            return <Navigate to={serverErrorRoute.path}/>
        default:
            return <Navigate to={unknownErrorRoute.path}/>
    }
}

export const renderLoadingDefault = () => <LoadingPlaceholder />

export const standardResponseToDataTransformation = <TData extends unknown>(
    response: StandardApiResponse<TData>
) => response.data;

export const defaultResponseTransformation = <TResponse, TData>(response: TResponse) => response as unknown as TData