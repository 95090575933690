import { lazy } from "react";
import { RouteConfig } from "../../../types/RouteConfig";
import { walletSchemeViewRoute } from "../scheme-view/route";

export const walletPageRoute: RouteConfig<'with-page'> = {
    name: 'My Wallet',
    path: '/wallet',
    icon: 'mdi mdi-wallet',
    page: lazy(() => import('.')),
    flags: ['member-route'],
    permissions: ['wallet'],
    dynamicChildren: [
        walletSchemeViewRoute,
    ]
}