import { RouteConfig } from "../../types/RouteConfig";
import { biopakPeriodsManagementRoute } from "./periods-management/route";
import { bioPakSignupManagementRoute } from "./signup-management/route";

export const biopakRoutes: RouteConfig<'with-children'> = {
    name: 'BioPak',
    icon: 'mdi mdi-package-variant',
    children: [
        biopakPeriodsManagementRoute,
        bioPakSignupManagementRoute,
    ]
}