import { lazy } from "react";
import { RouteConfig } from "../../types/RouteConfig";
import { errorMembershipRoute } from "../error/PageMembershipStatusError/route";

export const riskAssessmentsRoute: RouteConfig<'with-page'> = {
    name: 'Risk Assessments',
    page: lazy(() => import('.')),
    path: '/documents/risk-assessments',
    flags: ['member-route'],
    permissions: ['tradingUnits'],
    permissionsRedirect: errorMembershipRoute
}