import React, { createContext, useContext } from "react";
import createPersistedState from "use-persisted-state";
import { removeLocalStorageValue } from "../Logout/helpers";
import {asGuid, GuidString} from "../../models/BrandedTypes";

export type ImpersonationContextProperties = {
    membershipNumberOverride: string,
    accountIdOverride: GuidString,
}

export const localStorageKey = "persisted-membership-number-override"

export type ImpersonationContextValue = {
    membershipNumberOverride: string,
    accountIdOverride: GuidString,
    setImpersonationOverride: (impersonationProperties: ImpersonationContextProperties) => void,
    isImpersonatingMember: boolean,
}

const MembershipImpersonationContext = createContext<ImpersonationContextValue>({
    membershipNumberOverride: null,
    accountIdOverride: null,
    setImpersonationOverride: () => {},
    isImpersonatingMember: false,
})

export const logoutActions = [
    removeLocalStorageValue(localStorageKey),
]

export const ClearPersistedStorage = () => {
    localStorage.removeItem(localStorageKey);
}

const usePersistedMembershipNumberOverride = createPersistedState<ImpersonationContextProperties>(localStorageKey);

export const MembershipImpersonationProvider = ({children}: React.PropsWithChildren<{}>) => {
    const [impersonationOverride, setImpersonationOverride] = usePersistedMembershipNumberOverride(null);
        
    let accountIdOverride = impersonationOverride?.accountIdOverride ?? null;
    let membershipNumberOverride = impersonationOverride?.membershipNumberOverride ?? null;

    const contextValue: ImpersonationContextValue = {
        membershipNumberOverride,
        accountIdOverride,
        setImpersonationOverride,
        isImpersonatingMember: membershipNumberOverride !== null && accountIdOverride !== null,
    };

    return (
        <MembershipImpersonationContext.Provider value={contextValue}>
            {children}
        </MembershipImpersonationContext.Provider>
    )
}

export const useMembershipImpersonationContext = () => useContext(MembershipImpersonationContext);