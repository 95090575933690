import { createContext, PropsWithChildren, useMemo, useState } from "react";
import LoadingPlaceholder from "../components/LoadingPlaceholder";
import { FlattenedRoute, flattenRoutes, mapParentRoutes } from "./helpers";
import { useIsAuthorized } from "./hooks/useIsAuthorized";
import { homeRoute, routes } from "./pages/routes";
import { RouteConfig } from "./types/RouteConfig";

export type RouteContextType = {
    activeRoute: RouteConfig<'with-page'>,
    setActiveRoute: (route: RouteConfig<'with-page'>) => void,
    routes: RouteConfig[],
    homeRoute: RouteConfig<'with-page'>,
    isAuthorized: (route: RouteConfig) => boolean,
    routeParents: Map<RouteConfig, RouteConfig[]>,
    flattenedRoutes: FlattenedRoute[]
}

const placeholderHomeRoute: RouteConfig<'with-page'> = {
    path: '/',
    name: 'Initial Home',
    page: () => <LoadingPlaceholder />,
}

export const RouteContext = createContext<RouteContextType>({
    activeRoute: placeholderHomeRoute,
    setActiveRoute: () => {},
    routes: [placeholderHomeRoute],
    homeRoute: placeholderHomeRoute,
    isAuthorized: () => false,
    routeParents: new Map<RouteConfig, RouteConfig[]>([[placeholderHomeRoute, []]]),
    flattenedRoutes: [[placeholderHomeRoute, placeholderHomeRoute]]
})

export const RouteProvider = ({children}: PropsWithChildren<{}>) => {
    const [activeRoute, setActiveRoute] = useState<RouteConfig<'with-page'>>(homeRoute);

    const isAuthorized = useIsAuthorized();

    const routeParents = useMemo(() => mapParentRoutes(routes), []);
    const flattenedRoutes = useMemo(() => flattenRoutes(routes), []);

    const contextValue = useMemo<RouteContextType>(() => ({
        activeRoute,
        setActiveRoute,
        homeRoute,
        routes,
        isAuthorized,
        routeParents,
        flattenedRoutes
    }), [activeRoute, setActiveRoute, isAuthorized, routeParents, flattenedRoutes])

    return (
        <RouteContext.Provider value={contextValue}>
            {children}
        </RouteContext.Provider>
    )
}