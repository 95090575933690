import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export function useResetableState<T>(initialState: T | (() => T)): [T, Dispatch<SetStateAction<T>>, () => void] {
    const [state, setState] = useState(initialState);

    const resetState = useCallback(() => {
        setState(initialState);
    }, [initialState])

    return [state, setState, resetState];
}