import React from 'react';
import { Navigate } from 'react-router-dom';
import { badPermissionsRoute } from '../../routes/pages/error/PageBadPermissionsError/route';
import { RouteConfig } from '../../routes/types/RouteConfig';
import { useMemberPermissions } from './context';
import { MemberPermissionsCategory } from './types';

type MemberPermissionsWrapperProps = React.PropsWithChildren<{
    requiredPermissions: MemberPermissionsCategory[],
    permissionsRedirect?: RouteConfig<'with-page'>,
}>

const MemberPermissionsWrapper = ({children, requiredPermissions, permissionsRedirect}: MemberPermissionsWrapperProps) => {
    const permissions = useMemberPermissions();
    const meetsPermissions = requiredPermissions.every(permissionType => permissions[permissionType] === 'FullAccess');
    return meetsPermissions
        ? <>{children}</>
        : permissionsRedirect?  <Navigate to={permissionsRedirect.path}/> : <Navigate to={badPermissionsRoute.path}/>;
}

export const createMemberPermissionsWrapper = (requiredPermissions: MemberPermissionsCategory[], Page: React.ComponentType, permissionsRedirect: RouteConfig<'with-page'>) => {
    return () => (
        <MemberPermissionsWrapper requiredPermissions={requiredPermissions} permissionsRedirect={permissionsRedirect}>
            <Page />
        </MemberPermissionsWrapper>
    )
}