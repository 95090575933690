import { RouteConfig } from "../../types/RouteConfig";
import { coshhFormRoute } from "./company-documents/coshh-form/route";
import { healthAndSafetyPolicyRoute } from "./company-documents/health-and-safety-policy/route";
import { companyDocumentsRoute } from "./company-documents/route";
import { foodSafetyRiskAssessmentRoute } from "./unit-documents/unit-risk-assessments/food-safety-risk-assessment/route";
import { healthAndSafetyRiskAssessmentRoute } from "./unit-documents/unit-risk-assessments/health-and-safety-risk-assessment/route";
import { fireSafetyRiskAssessmentRoute } from "./unit-documents/unit-risk-assessments/fire-risk-assessment/route";
import { riskAssessmentsRoute } from "../risk-assessments/route";

export const documentsRoutes: RouteConfig<'with-children'> = {
    name: 'Documents',
    icon: 'mdi mdi-file-outline',
    children: [
        riskAssessmentsRoute,
        companyDocumentsRoute,
        healthAndSafetyPolicyRoute,
        coshhFormRoute,
        foodSafetyRiskAssessmentRoute,
        healthAndSafetyRiskAssessmentRoute,
        fireSafetyRiskAssessmentRoute
    ],
}