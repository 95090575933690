import { lazy } from "react";
import { RouteConfig } from "../../../types/RouteConfig";
import { biopakPeriodManagementRoute } from "../period-management/route";

export const biopakPeriodsManagementRoute: RouteConfig<'with-page'> = {
    name: 'Periods',
    path: '/biopak/periods',
    page: lazy(() => import('.')),
    roles: ['ncassAdmin'],
    dynamicChildren: [
        biopakPeriodManagementRoute,
    ]
}