import { RouteConfig } from "../../types/RouteConfig";
import { bookerPeriodsManagementRoute } from "./periods-management/route";
import { bookerSignupManagementRoute } from "./signup-management/route";

export const bookerRoutes: RouteConfig<'with-children'> = {
    name: 'Booker',
    icon: 'mdi mdi-notebook',
    children: [
        bookerSignupManagementRoute,
        bookerPeriodsManagementRoute,
    ]
}   