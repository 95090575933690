import { RouteConfig } from "../../types/RouteConfig";
import { calorClaimsAuditViewRoute } from "./claims-audit-view/route";
import { calorClaimsManagementRoute } from "./claims-management/route";
import { calorPeriodsManagementRoute } from "./periods-management/route";
import { calorSignupManagementRoute } from "./signup-management/route";

export const calorRoutes: RouteConfig<'with-children'> = {
    name: 'Calor',
    icon: 'mdi mdi-fire',
    children: [
        calorPeriodsManagementRoute,
        calorClaimsManagementRoute,
        calorClaimsAuditViewRoute,
        calorSignupManagementRoute,
    ]
}